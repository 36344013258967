/********
 Reset
********/

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button {
  -webkit-appearance: none;
  border: none;
  background: transparent;
}

button:focus {
  outline: none;
}

body {
  background-color: #151821;
  font-family: sans-serif;
}

:root {
  /* Colors */
  --white: #fff;
  --green: #4fcba0;
  --darkgreen: #186b5e;
  --bordergreen: #54afa0;

  /* Typography */
  --leading-tight: 1;
  --tracking-sm: 0.05rem;

  /* Spaces */
  --space-tight-v: 0;
  --space-tight-h: 0;

  /* Alignment */
  --text-right: right;
  --text-left: left;
  --text-center: center;
}

/********

General

********/
#root {
  background-size: cover;
  background-position: 0;
}

.disable {
  pointer-events: none !important;
}

.box {
  position: relative;
  margin: 0.4rem auto 1rem;
}

.box__inner {
  background-color: var(--white);
  border-radius: 1.2rem;
  border: 1px solid #1d5d54;
  /*position: absolute;*/
  width: 100%;
  min-height: 75px;
  z-index: 2;
  -webkit-box-shadow: 0px 5px 0 0 #11a08ccf, 0 12px 0 0 #0d7367d6,
    0px 20px 10px 8px rgba(41, 41, 41, 0.76);
  box-shadow: 0px 5px 0 0 #11a08ccf, 0 12px 0 0 #0d7367d6,
    0px 20px 10px 8px rgba(41, 41, 41, 0.76);
}

/* .box__inner:before {
  content: 'Upcoming Game';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  color: #15bc9b;
  letter-spacing: 0.5px;
  font-size: 19px;
} */

.upcoming {
  font-weight: 700;
  font-size: 18px;
  color: #118676;
  letter-spacing: 0.4px;
  display: block;
  text-align: center;
  height: 100%;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.upcoming-full {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 1.2;
  padding: 7px 15px;
  position: initial;
  transform: none;
}

.box__back {
  color: #adc4bc;
  display: block;
  margin: -0.3rem 0 1rem;
  text-align: center;
  width: 100%;
  font-size: 0.8rem;
  letter-spacing: 0.04rem;
  text-decoration: underline;
}

/********

Nav

********/
.nav__header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  color: #dff1ea;
  font-size: 1rem;
  padding: 1rem;
  font-weight: 500;
  letter-spacing: 0.04rem;
  color: white;
  text-decoration: none;
}

.nav__header:hover,
.nav__header:active,
.nav__header:focus {
  color: white;
  opacity: 0.7;
  text-decoration: none;
}

.nav__header i {
  margin-right: 0.3rem;
}

.nav__app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: #cdd8e0;
}

.nav__app i {
  font-size: 20px;
}

.nav__back {
  background: #1eca9f;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#26ab89),
    to(#58cad2)
  );
  background: -o-linear-gradient(top, #26ab89 0%, #58cad2 100%);
  background: linear-gradient(to bottom, #26ab89 0%, #58cad2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ab89', endColorstr='#58cad2',GradientType=0 );
  left: 50%;
  top: 1%;
  z-index: 8;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 91%;
  padding: 0.5rem 1.8rem;
  border-radius: 0.3rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  color: white;
  border-radius: 50px;
}

.nav__back:after {
  content: "";
  width: 91%;
  background-color: rgba(255, 255, 255, 0.32);
  position: absolute;
  top: 3px;
  left: 50%;
  height: 8px;
  border-radius: 50px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

/********

Game List

********/
.gamelist {
  height: 100%;
  background-image: url("/images/gamelist.jpg");
  background-size: 100%;
  padding-top: 2.5rem;
  padding-bottom: 4rem;
  /*overflow-y: auto;*/
  will-change: auto;
}

.gamelist__title {
  font-size: 2.5em;
  font-weight: 700;
  color: var(--white);
  margin: var(--space-tight-h) var(--space-tight-v);
  line-height: var(--leading-tight);
  text-align: var(--text-right);
  /*overflow-y: scroll;*/
}

.gamelist__label {
  color: var(--green);
  font-weight: 600;
  text-align: var(--text-right);
  letter-spacing: var(--tracking-sm);
}

.gamelist__items {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.9rem 1.4rem;
  border-bottom: 1px solid #dde8e7;
}

.gamelist__items:last-of-type {
  border-bottom: none;
}

.gamelist__name {
  display: block;
  color: var(--darkgreen);
  font-weight: 800;
  font-size: 1.1rem;
  line-height: 1.1;
  letter-spacing: 0.02rem;
  -webkit-box-flex: 1.8;
  -ms-flex: 1.8 1;
  flex: 1.8 1;
}

.gamelist__name span {
  display: block;
  font-size: 80%;
  font-weight: 700;
  color: #25b39f;
  margin-top: 0.2em;
}

.gamelist__name .sunday {
  font-weight: 500;
  font-size: .8rem;
  color: #ff9a00;
  margin-top: .5rem;
}

.gamelist__board {
  display: block;
  font-size: 70%;
  margin-top: 0.5rem;
  text-align: var(--text-center);
  color: var(--darkgreen);
  opacity: 0.7;
  font-weight: 600;
}

.gamelist__button {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: var(--text-center);
}

.gamelist__button button,
.gamelist__button a {
  -webkit-appearance: none;
  border: none;
  background-color: #25b39f;
  -webkit-box-shadow: 0 3px 1px 0 var(--darkgreen);
  box-shadow: 0 3px 1px 0 var(--darkgreen);
  font-size: 1.1rem;
  font-weight: 800;
  color: var(--white);
  border-radius: 10rem;
  padding: 0.5rem;
  display: block;
  width: 90%;
  margin: 0 0 0 auto;
  letter-spacing: 0.04rem;
  text-shadow: 0 2px 5px #48a082;
}

.gamelist__button button:hover,
.gamelist__button button:active,
.gamelist__button button:focus,
.gamelist__button a:hover,
.gamelist__button a:active,
.gamelist__button a:focus {
  background-color: #24cab2;
  text-decoration: none;
}

.gamelist__notif {
  position: relative;
}

.gamelist__notifcount {
  background: rgb(255, 67, 67);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 67, 67, 1)),
    to(rgba(234, 12, 12, 1))
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 67, 67, 1) 0%,
    rgba(234, 12, 12, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 67, 67, 1) 0%,
    rgba(234, 12, 12, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff4343', endColorstr='#ea0c0c',GradientType=0 );
  position: absolute;
  right: -15px;
  top: -10px;
  width: auto;
  height: 1.7rem;
  border-radius: 50px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 2px solid white;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0 9px;
}

.gamelist__infos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(8, 18, 32, 0.9);
  padding: 11px;
  border-radius: 11px;
  margin-top: 10px;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.gamelist__infos2,
.gamelist__infos3 {
  opacity: 1;
  visibility: hidden;
  padding: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
}

.gamelist__infos a {
  color: #fff;
  text-decoration: none;
}

.gamelist__infos > ion-icon {
  color: #42bcb2;
  font-size: 1.9rem;
  position: absolute;
  top: 3.5rem;
  right: -0.6rem;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gamelist__info {
  color: white;
  text-align: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  -webkit-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

.gamelist__info--icon {
  position: relative;
}

.gamelist__info--icon > ion-icon {
  position: absolute;
  bottom: 0.2rem;
  right: 0.5rem;
  font-size: 1.4rem;
  color: #25bf40;
  background-color: #050a0e;
  border-radius: 100%;
}

.gamelist__info--icon--error > ion-icon {
  color: #ff4f32;
}

.gamelist__info img {
  margin-bottom: 8px;
  width: 40px;
  max-height: 40px;
  min-height: 40px;
  /* width: 25px; */
}

.gamelist__info h4 {
  font-size: 11px;
  line-height: 1.3;
  letter-spacing: 0.8px;
  margin: 4px auto 0;
  color: #7a96bd;
  font-weight: 400;
  display: none;
  text-align: center;
}

.gamelist__info h4 > span {
  display: block;
  font-size: 85%;
  letter-spacing: 0;
  color: rgba(150, 227, 255, 0.88);
}

.gamelist__info p {
  font-size: 0.95rem;
  font-weight: 700;
  letter-spacing: 0.04rem;
}

.gamelist__info .learnMore {
  font-size: 10px;
  display: block;
  margin-top: 6px;
  color: #fff;
  letter-spacing: 0;
}

.gamelist__info p.error {
  color: #ff5a3f;
}

.gamelist__infos1 .gamelist__info h4 {
  max-width: 65px;
  height: 1rem;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.gamelist__infos--expanded .gamelist__info h4 {
  display: block;
}

.gamelist__infos--expanded.gamelist__infos1 .gamelist__info h4 {
  overflow: visible;
  height: auto;
  max-width: 88%;
  white-space: normal;
}

/* .gamelist__infos--expanded .gamelist__info p {
  font-size: 17px;
} */

/* .gamelist__infos--expanded .gamelist__info img {
  width: 40px;
} */

.gamelist__infos2.gamelist__infos--expanded,
.gamelist__infos3.gamelist__infos--expanded {
  opacity: 1;
  visibility: visible;
  padding: 11px;
  margin: 10px 0 0;
}

.gamelist__infos2.gamelist__infos--expanded {
  height: 140px;
}

.gamelist__infos3.gamelist__infos--expanded {
  height: 160px;
}


.expandStat {
  position: absolute;
  top: 0;
  right: 0;
}

.expandStat ion-icon {
  color: #7ecbbb;
}

/********

Tab bar

********/
.tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #151821;
  z-index: 999;
}

.tabbar > div {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  /* align-items: start; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 7px 0;
  z-index: 999;
}

.tabbar__btn {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-appearance: none;
  border: none;
  background: transparent;
  outline: none;
  word-break: break-all;
}

.tabbar__btn:focus {
  outline: none;
}

.tabbar__btn span {
  color: #5d6271;
  font-size: 72%;
  display: block;
  line-height: 1.1;
  font-weight: 500;
  letter-spacing: 0.02rem;
  white-space: normal;
  word-break: normal;
}

.tabbar__btn img {
  width: 32px;
}

@media screen and (max-height: 600px) {
  .tabbar > div {
    padding: 4px 0;
  }
  .tabbar__btn img {
    width: 28px;
  }
  .tabbar__btn span {
    font-size: 60%;
  }
}

/********

Modal

********/
.modal {
  top: -2%;
}
.modal-header {
  padding: 1rem 1rem 0.5rem;
  border-bottom: 1px dashed #b3bdbb;
}

.modal-title button {
  position: absolute;
  top: -2.8rem;
  right: 0.5rem;
  border-radius: 100%;
  width: 2.1rem;
  height: 2.1rem;
  padding: 0;
  background-color: #08cc7f;
  border: none;
  /*box-shadow: 0 3px 0 0 #0f774e;*/
}

.modal-title {
  width: 100%;
}

.modal-title img {
  width: 7rem;
  display: block;
  margin: -3rem auto 0;
  position: relative;
  left: 1rem;
}

.modal-title span,
.modal-title p {
  color: black;
  display: block;
  margin-top: -2.5rem;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  position: relative;
  z-index: 2;
  letter-spacing: 0.03rem;
}

.modal-title p {
  margin-top: 0;
}

.modal-title strong {
  margin: 0 auto;
  display: block;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.01rem;
  font-size: 85%;
  line-height: 1.2;
  width: 85%;
}

.modal-body {
  padding: 0;
  background-color: #fff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  /* background-image: url(/images/modal-bg-new.jpg); */
  background-size: cover;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-position: center center;
}

.modal-content {
  margin: 0 auto;
  border-radius: 1rem;
  width: 95%;
  min-height: 20rem;
  -webkit-box-shadow: 0 6px 0 0 #11a08ccf, 0px 14px 0 0 #0d7367d6,
    0px 20px 10px 8px rgba(41, 41, 41, 0.76);
  box-shadow: 0 6px 0 0 #11a08ccf, 0px 14px 0 0 #0d7367d6,
    0px 20px 10px 8px rgba(41, 41, 41, 0.76);
  /*box-shadow: 0px 7px 0 0 #d8d8d8e0, 0px 15px 0 0 rgba(187, 187, 187, 0.76), 0px 20px 10px 8px rgba(41, 41, 41, 0.76);*/
}

.modal-submit {
  background: #02d2ff;
  background: -o-linear-gradient(315deg, #14e8ce 1%, #55e4ae 100%);
  background: linear-gradient(135deg, #14e8ce 1%, #55e4ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#14E8CE", endColorstr="#55E4AE",GradientType=1 );
  -webkit-box-shadow: 0 2px 0 0 #45ada1, 0 2px 5px 0 #45ada1;
  box-shadow: 0 2px 0 0 #45ada1, 0 2px 5px 0 #45ada1;
  display: block;
  width: 90%;
  text-align: center;
  margin: 1rem auto 1.5rem;
  color: var(--white);
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.9rem 0;
  border-radius: 0.5rem;
  letter-spacing: 2px;
  -webkit-appearance: none;
  border: none;
}

.modal-submit:hover,
.modal-submit:active,
.modal-submit:focus {
  color: white;
  text-decoration: none;
}

.modal-trap {
  background-color: rgba(8, 18, 32, 0.98);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  pointer-events: none;
  color: #fff;
  font-weight: 700;
  font-size: 2rem;
  padding: 0 2rem;
  text-align: center;
  line-height: 1.2;
  overflow: hidden;
  cursor: not-allowed;
}

.modal-trap img {
  max-width: 6rem;
  margin-bottom: 1rem;
}

/********

Modal: Language

********/
.language__modal .modal-content {
  min-height: 8rem;
}
.language__modal .modal-title span {
  margin-top: -0.3rem;
}
.language__title {
  font-size: 2rem;
  margin: 1.5rem 1rem;
  text-align: center;
  font-weight: 700;
  letter-spacing: -1px;
  color: #3c3c3c;
}

/********

Modal: Qualified

********/

.qualified .modal-header {
  padding: 0;
  border: none;
}

.qualified .modal-content {
  min-height: unset; /* Remove default min-height */
}

.qualified__content {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.qualified__content input[type="checkbox"] {
  display: none;
}

.qualified__content li {
  border-bottom: 1px solid #f1ebeb;
}
.qualified__content li > label {
  padding: 1rem;
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.qualified__content li div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.qualified__content li p {
  font-weight: 600;
  width: 100%;
  letter-spacing: 0.01rem;
  line-height: 1.3;
}
.qualified__content li:first-child {
  text-align: center;
  margin: 0 auto;
  display: block;
}

.qualified__content li p em {
  display: block;
  font-size: 75%;
  font-weight: 500;
  margin-top: 0.4rem;
  line-height: 1.3;
  color: #0d8455;
}

.qualified__content li span {
  font-size: 1.4rem;
  font-weight: 800;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 1rem;
}

.qualified__content li span i {
  font-size: 1rem;
  margin-left: 0.5rem;
}

.qualified__content li > ul {
  height: 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 7.5rem;
  -webkit-transition: height 0.2s ease-out;
  -o-transition: height 0.2s ease-out;
  transition: height 0.2s ease-out;
  background-color: whitesmoke;
  -webkit-box-shadow: 0 0 10px 0 #ddd inset;
  box-shadow: 0 0 10px 0 #ddd inset;
}

.qualified__content input[type="checkbox"]:checked ~ ul {
  height: auto;
}

.qualified__content li > ul li {
  padding: 0.6rem 0.5rem;
  text-align: left !important;
  font-weight: 500;
  font-size: 90%;
  padding-left: 1rem;
  color: #3c3c3c;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-transform: capitalize;
}

.qualified__content li > ul li span {
  font-size: 1rem;
  font-weight: 600;
  margin-right: 0.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.qualified__content li > ul li.static {
  color: #d01d1d;
}

.qualified__content li small {
  font-size: 70%;
  font-weight: 400;
  margin-top: -0.6rem;
  margin-bottom: 1rem;
  display: block;
  color: grey;
}

.qualified__content img {
  width: 2.5rem;
  margin-right: 0.5rem;
}

/********

Modal: Prizes

********/
.prize.modal-body {
  height: 380px;
  padding: 0;
}

.prizes {
  text-align: center;
  margin-top: 0.8rem;
}

.prizes:last-of-type {
  margin-bottom: 1.4rem;
}

.prizes img {
  width: 4rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0 auto;
}

.prizes p {
  color: #067d4e;
  font-size: 1.4rem;
  font-weight: 800;
  letter-spacing: -0.04rem;
  margin: 0.4rem 0 0.1rem;
}

.prizes span {
  font-size: 90%;
  font-weight: 600;
  letter-spacing: 0.3px;
  max-width: 90%;
  display: block;
  margin: 0.4rem auto 0;
}

.prize__tab {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.prize__tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0.5rem 0;
}

.prize__tabs > label {
  padding: 0.5rem 2rem 0.3rem;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.02rem;
  font-size: 1rem;
  border-radius: 0.4rem;
  line-height: 1;
  min-width: 8rem;
}

.prize__tab input[name="prize__tab"] {
  display: none;
}

.prize__content > div {
  display: none;
  min-height: 300px;
  -webkit-transition: all 500ms;
  -o-transition: all 500ms;
  transition: all 500ms;
}

.prize__content > div > p {
  text-align: center;
  font-weight: 500;
  font-size: 82%;
  letter-spacing: 0.01rem;
  margin: 1.2rem 0 0.5rem;
  color: #c19513;
}

.daily__content > .slider,
.weekly__content > .slider,
.daily__content > .slider-list,
.weekly__content > .slider-list {
  min-height: 300px !important;
}

#daily:checked ~ .prize__tabs > .daily__label,
#weekly:checked ~ .prize__tabs > .weekly__label {
  border-bottom: 0.2rem solid #04c57a;
  color: #e0fff3;
  background-color: #04c57a;
  -webkit-box-shadow: 0 3px 0 0 #06945d;
  box-shadow: 0 3px 0 0 #06945d;
}

#daily:checked ~ .prize__content > .daily__content,
#weekly:checked ~ .prize__content > .weekly__content {
  display: block;
}

.combo520 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.combo520 img {
  margin: 0 0.5rem;
}

.required {
  color: red !important;
}
/********

Modal: Winning Circle

********/
.winners,
.winners__charity,
.winners__defined,
.personal__charity,
.charity__diagram,
.winners_play {
  text-align: center;
  padding: 1rem 1.6rem;
  /*height: 330px;*/
}

.winner__diagram,
.charity__diagram,
.winners_play {
  text-align: center;
  padding: 1rem 1.6rem;
  height: 430px !important;
}

.winners__defined {
  text-align: center;
  padding: 1rem 1.6rem;
  height: 360px !important;
}
.winners span,
.winners__portal span,
.winners__charity span,
.personal__charity span,
.winners_play span {
  color: #119683;
}
.winner__diagram p:first-child,
.winners__defined p:first-child,
.charity__diagram p:first-child,
.winners_play p:first-child {
  margin-top: 0.5rem;
}
.winners__portal {
  /* height: 380px; */
  padding: 1rem 1.6rem 2rem 1.6rem;
  text-align: center;
}

.winners__circle {
  height: 280px;
  display: block;
  padding-top: 2rem;
}

.winners__portal .slider-control-bottomcenter {
  bottom: -9% !important;
}

.winners__charity .slider-control-bottomcenter {
  bottom: -7% !important;
}
.personal__charity .slider .slider-control-bottomcenter {
  bottom: -6% !important;
}

.winners__portal .settings li {
  padding: 0.5rem 1.3rem 0 0.8rem;
  text-align: left;
}

.settings-2 {
  display: none;
}
.winners__portal__desc p {
  margin-top: 0.8rem;
}

i {
  font-style: italic;
}

img.twelve {
  max-width: 150px !important;
}

img.adjustments {
  width: 70% !important;
  margin: 0rem auto !important;
}

img.adjustments2 {
  width: 70% !important;
  margin: 0rem auto !important;
}

img.adjustments3 {
  width: 60% !important;
  margin: 1rem auto !important;
}

img.adjustments4 {
  width: 85% !important;
  margin: 0rem auto !important;
}
img.adjustments5 {
  width: 60% !important;
  margin: 0rem auto !important;
}

span.highlights img {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  max-width: 130px;
  padding-right: 1rem;
}

span.clickReveal img {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  max-width: 90px;
  padding-right: 1rem;
}

img.winnerHighlight {
  width: 100% !important;
  max-width: 9rem !important;
}

.winners p,
.winner__diagram p,
.winners__portal p,
.winners__charity p,
.winners__defined p,
.personal__charity p,
.charity__diagram p,
.winners_play p {
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.01rem;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  background: #ffffff69;
}

.winners p:last-of-type,
.winners__portal p:last-of-type {
  margin-bottom: 0;
}

.winners img,
.personal__charity img,
.winners_play img {
  width: 100%;
  height: auto;
  display: block;
  margin: 1rem auto;
  max-width: 200px;
}

.winners__defined img {
  width: 100%;
  height: auto;
  display: block;
  margin: 1rem auto;
  max-width: 230px;
}

.winner__diagram img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0rem auto;
  max-width: 210px !important;
}

.charity__diagram img {
  max-width: 150px;
  margin: -0.5rem auto;
}

.winners__game .modal-body {
  border-radius: 17px;
}

.winners__game .modal-header {
  padding: 0;
  border: none;
}

.winners_text {
  letter-spacing: 0.03rem;
  font-size: 1rem;
  color: #e68118;
}

.winners__game h2 {
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  letter-spacing: -0.6px;
  text-shadow: 1px 2px 0 #ffffff, -1px 2px 0px #ffffff, 1px -2px 0px #ffffff,
    2px 2px 0px #ffffff;
}

.winners__game h2 span {
  display: block;
  font-size: 66%;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin-bottom: 4px;
}

.winners__game p {
  text-align: center;
  font-weight: 500;
  margin: 20px auto 0;
  line-height: 1.3;
  font-size: 15px;
  letter-spacing: 0.2px;
  width: 85%;
}

.winners__game p > img {
  width: 36px;
  display: inline;
  margin: 6px 0 -12px 6px;
}

.winners_play u {
  color: #119683;
}

.winners_play em {
  font-size: 0.7rem;
  font-weight: 300;
  color: gray;
  display: block;
  margin: 0rem auto;
}

.winners__gamebody {
  background-image: url(/images/confetti.png);
  background-color: white;
  background-size: 110%;
  background-position: center 97%;
  padding: 70px 20px 30px;
}

.winners__gamebody > img {
  width: 140px;
  display: block;
  margin: 30px auto;
}

.winners__winnings {
  height: 330px;
  display: block;
}

.winners__definded {
  padding: 0rem 1.6rem;
}

.winners__defined .slider .slider-control-bottomcenter {
  bottom: -2% !important;
}

.charity__diagram .slider .slider-control-bottomcenter {
  bottom: -2% !important;
}

.winners.winners__winnings .slider .slider-frame ul li div > p:first-of-type {
  margin-top: 15px;
}

.winners.winners__winnings .slider .slider-control-bottomcenter {
  bottom: 9% !important;
}

.winner__diagram .slider .slider-control-bottomcenter {
  bottom: 0% !important;
}

/********

Modal: Settings

********/
.settings li {
  padding: 0.8rem 1.3rem;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.03rem;
  border-bottom: 1px solid #e1ece9;
  background-color: rgba(255, 255, 255, 0.67);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.settings li i {
  color: #119683;
}

.settings li a {
  text-decoration: none;
  color: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.settings li:last-of-type {
  border-bottom: none;
}

.settings li span {
  display: block;
  font-size: 65%;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0.2rem;
  opacity: 0.6;
}

.backToApp:last-child p,
.backToApp:last-child i {
  color: #08cc7f;
}

.backToApp:last-child i {
  font-size: 1.5rem;
}

/********

Modal: Transaction

********/
.transactionHistory__breakdown {
  padding: 0.8rem 1.3rem;
  line-height: 1.5;
  /*font-variant: all-petite-caps;*/
  letter-spacing: 0.03px;
  font-weight: 600;
}
.transactionHistory__breakdown span {
  color: #10675c;
  font-weight: 500;
}
.transaction__amount {
  text-align: center;
  margin: 1rem 0;
}

.transaction__amount p {
  font-size: 2.3rem;
  font-weight: 700;
  letter-spacing: -0.03rem;
}

.transaction__amount span {
  letter-spacing: 0.02rem;
  font-size: 70%;
}

.transaction__input {
  margin: 1.5rem 0;
}

.transaction__input label {
  font-size: 80%;
  display: block;
  width: 90%;
  margin: 0.2rem auto 0.3rem;
  letter-spacing: 0.03rem;
}
.transaction__icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.7rem;
}
.transaction__icons img {
  max-width: 45px;
  /*mix-blend-mode: darken;*/
  margin-right: 15px;
}
.transaction__icons p {
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 800;
  margin-bottom: 0.3rem;
  letter-spacing: 0.03rem;
}
.transaction__icons h4 {
  text-transform: capitalize;
  line-height: 1;
  letter-spacing: 0.02rem;
  font-size: 0.83rem;
  margin-top: 0.2rem;
  color: #4a4a4a;
  font-weight: 500;
}
.transaction__icons small {
  font-weight: 400;
  text-transform: capitalize !important;
  margin: 0.2rem 0 0 0 !important;
  line-height: 1.3;
  font-size: 71% !important;
  color: #9c9b9b !important;
  letter-spacing: 0.01rem;
}
.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 5rem;
  margin: 1rem auto;
}
.email-setup {
  font-size: 80%;
  font-weight: 500;
  letter-spacing: 0.03rem;
  padding: 0 1rem;
}

.form-inline button {
  cursor: pointer;
}
.form-inline input {
  background-color: #e6ebec;
  display: block;
  -webkit-appearance: none;
  border: none;
  padding: 0.5rem 0.8rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 0.5rem;
  width: 70%;
  margin: 1rem 1.5rem;
}
.form-inline button {
  background: #02d2ff;
  background: -o-linear-gradient(315deg, #14e8ce 1%, #55e4ae 100%);
  background: linear-gradient(135deg, #14e8ce 1%, #55e4ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#14E8CE", endColorstr="#55E4AE",GradientType=1 );
  -webkit-box-shadow: 0 2px 0 0 #45ada1, 0 2px 5px 0 #45ada1;
  box-shadow: 0 2px 0 0 #45ada1, 0 2px 5px 0 #45ada1;
  text-align: center;
  color: var(--white);
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-appearance: none;
  border-radius: 0.5rem;
  width: 23%;
  padding: 0.7rem 0;
}
.transaction__input input {
  background-color: #e6ebec;
  display: block;
  -webkit-appearance: none;
  border: none;
  padding: 0.5rem 0.8rem;
  font-size: 1.2rem;
  font-weight: 600;
  width: 90%;
  margin: 0 auto;
  border-radius: 0.2rem;
}

.transaction__breakdown {
  padding: 0 1.5rem;
}

.transaction__breakdown li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.4rem 0;
}

.transaction__breakdown hr {
  margin: 0.2rem 0 0.5rem;
  border-top: 0;
  border-bottom: 1px solid #deeae7;
  width: 100%;
}

.transaction__breakdown span {
  font-size: 80%;
  font-weight: 500;
  letter-spacing: 0.03rem;
}

.transaction__breakdown span a {
  font-weight: 500;
  font-size: 80%;
  margin-top: 0.4rem;
  color: #119583;
  text-decoration: underline;
}

.transaction__breakdown p {
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.transaction__success {
  text-align: center;
  margin: 3rem 0;
}

.transaction__success p {
  font-size: 1.2rem;
  font-weight: 700;
  /* margin-top: 0.3rem;
  margin-bottom: 3rem; */
  letter-spacing: -0.01rem;
  line-height: 1.8rem;
  margin: 1rem 1.5rem;
  text-align: center;
}

.transaction__success p > a {
  color: #13be7b;
  font-size: 1.2rem;
}
.transaction__success p > a:hover {
  color: #13be7b;
  text-decoration: underline;
}

.transaction__success i {
  font-size: 4rem;
  color: #13be7b;
}

.transaction__page {
  height: 100vh;
  background-image: url(/images/gamelist.jpg);
  background-size: cover;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.transaction__page .box__inner {
  position: relative;
  min-height: unset;
  background-color: #f4f7f6;
}

.transaction__innerpage {
  width: 90%;
}
.transaction__innerpage > .box__inner {
  max-height: 60vh;
  overflow-y: auto;
}
.transaction__innerpage > .box__inner small.scrollMore {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 0;
  letter-spacing: 0.02rem;
  font-weight: 500;
  font-size: 72%;
}

.transaction__innerpage small {
  display: block;
  font-size: 90%;
  color: #5da299;
  margin-top: 1rem;
}

.transaction__innerpage small:first-of-type {
  margin-top: 0.5rem;
}

.transaction__title {
  background-color: white;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: -0.01rem;
  padding: 1rem;
  border-bottom: 1px dashed #d4d6d5;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

/********

Modal: History

********/
/* .history {
  /* max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
} */

.history li {
  padding: 0.8rem 1.3rem;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
  border-bottom: 1px solid #e1ece9;
}

.history li p,
.history li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.history li p > em {
  display: block;
}

.history li p > em span {
  display: block;
  margin-bottom: 4px;
  margin-top: 0;
  letter-spacing: 0.03px;
}

.history li p span {
  display: block;
  font-size: 67%;
  font-weight: 600;
  margin-top: 0.3rem;
  color: #10675c;
}

.history li a {
  text-decoration: none;
  color: inherit;
}

.history li i {
  color: #afafaf;
}

.history li:last-of-type {
  border-bottom: none;
}

.history__details {
  font-weight: 400;
  display: block;
  margin: 0.6rem 0 0.2rem;
  padding-top: 0.5rem;
}

.history__details span {
  line-height: 1.2;
  margin: 0.2rem 0 0.6rem;
  display: block;
  font-size: 80%;
  font-weight: 600;
  letter-spacing: 0.05rem;
}

.history__details small {
  font-size: 80%;
  color: #5da299;
}

.history__details__collapse {
  font-weight: 400;
  display: block;
  margin: 0;
  padding-top: 0;
  background-color: #fff;
  margin-top: 1px;
}

.history__details__collapse a {
  font-size: 1rem;
  font-weight: 600;
  display: block;
  padding: 0.6rem 1.3rem;
  color: black;
  border-bottom: 1px solid #e1ece9;
}

/********

Modal: Profile

********/

.profile__info {
  border-bottom: 1px solid #e1ece9;
  padding: 0.6rem 1rem;
}

.profile > .profile__info:first-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.profile > .profile__info:first-of-type p {
  font-size: 1.1rem;
  font-weight: 700;
}

/*.profile__info:last-of-type {
  border-bottom: 0;
}*/

.profile__info small {
  letter-spacing: 0.02rem;
  margin-top: 0;
  font-size: 80%;
}

.profile__info p {
  font-weight: 600;
  letter-spacing: 0.02rem;
  margin: 0.2rem 0 0;
}

.profile__pic {
  border-radius: 100%;
  width: 3.2rem;
  height: 3.2rem;
  margin: 0.3rem 0.7rem 0.3rem 0;
  display: inline;
}

.profile__info__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.profile__info__group > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}


/********

Save your email - SETTINGS

********/
.email_title {
  margin: 1rem .5rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.3;
  text-align: center;
}
.email__info {
  padding: 1rem 1rem 0 1rem;
}
.email__info small {
  display: block;
  margin-bottom: 0.7rem;
  font-size: 90%;
  line-height: 1.3;
  color: #939393;
}
.email__input {
  border: 1px solid #f1f1f1;
  border-radius: 0.4rem;
  padding: 0.8rem 1rem;
  width: 100%;
  display: revert;
  background-color: #fff;
}
.email__invalid {
  font-size: 80%;
  color: #eb5959;
  display: block;
  text-align: center;
  margin-bottom: 0.5rem;
}
.email__processing {
  pointer-events: none;
  filter: grayscale(1);
}
.email__success {
  margin-bottom: 0.7rem;
  text-align: center;
}
.email__success button {
  display: block;
  margin: 1rem auto;
  text-decoration: underline;
}
.email_center {
  text-align: center;
}

/********

Onboard Slider - PRIZE

********/
.winners .slider > div {
  bottom: 5% !important;
  padding-top: 0.1rem !important;
}

.winners .slider .slider-control-bottomcenter {
  bottom: 13% !important;
}

.prize {
  padding: 0;
}

.prize,
.winners .slider-frame {
  height: 370px;
}

.prize .slider-control-centerleft,
.prize .slider-control-centerright,
.winners .slider-control-centerleft,
.winners .slider-control-centerright,
.winners__portal .slider-control-centerleft,
.winners__portal .slider-control-centerright,
.winners__charity .slider-control-centerleft,
.winners__charity .slider-control-centerright,
.winners__defined .slider-control-centerleft,
.winners__defined .slider-control-centerright,
.winner__diagram .slider-control-centerleft,
.winner__diagram .slider-control-centerright,
.personal__charity .slider-control-centerleft,
.personal__charity .slider-control-centerright,
.charity__diagram .slider-control-centerleft,
.charity__diagram .slider-control-centerright,
.winners_play .slider-control-centerleft,
.winners_play .slider-control-centerright,
.help .slider-control-centerleft,
.help .slider-control-centerright {
  display: none !important;
}

.paging-item button {
  padding: 0 0.2rem;
}

/********

Modal: Help

********/
.help {
  margin: 0;
}

.help > .modal-content {
  height: 100vh;
  width: 100%;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/********

Onboard Slider - HELP

********/
.help .slider-frame {
  background-image: url(/images/gamelist.jpg);
  background-size: cover;
  height: 100vh !important;
}

.help .slider-slide {
  height: 100vh !important;
  padding: 1rem 2rem;
}

.help .slider-slide > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.help .slider-close {
  position: absolute;
  top: 1.5rem;
  right: 0.6rem;
  border-radius: 100%;
  width: 2.1rem;
  height: 2.1rem;
  padding: 0;
  background-color: #08cc7f;
  border: none;
}

.help .slider-body {
  padding: 0.6rem 1rem;
  text-align: center;
}

.help .slider-body small {
  text-transform: uppercase;
  font-size: 75%;
  letter-spacing: 0.1rem;
  font-weight: 500;
  display: block;
  margin-bottom: 0.6rem;
  color: #119583;
}

.help .slider-body > img {
  width: 4rem;
  margin: 0 auto 0.5rem;
}

.help .slider-body li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-bottom: 0.4rem;
}

.help .slider-body li > p {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2;
}

.help .slider-body li > p span {
  display: block;
  font-weight: 700;
  font-size: 0.85rem;
  margin-bottom: 0.1rem;
  color: #119583;
}

.help .slider-body li > img {
  width: 2.5rem;
  margin-right: 1rem;
}

.help .slider-slide h1 {
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  color: var(--white);
}

.help .slider-slide h1 span {
  color: #00ffc8;
}

.help .slider-slide p {
  line-height: 1.15;
  font-weight: 500;
  letter-spacing: 0.03rem;
  font-size: 90%;
  margin-bottom: 0.5rem;
}

.help .slider-text p {
  color: var(--white);
  line-height: 1.2;
}

.help .slider-text li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  /*background-color: rgba(80, 85, 103, 0.61);*/
  /*border-radius: 14px;*/
  padding: 3px 0;
  /*border-bottom: 1px solid white;*/
}

.help .slider-text li p {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #b6bac7;
}

.help .slider-text li p span {
  font-weight: 600;
  display: block;
  margin-bottom: 2px;
  font-size: 16px;
  color: white;
}

.help .slider-text li img {
  width: 50px;
  margin-right: 10px;
}

.help .slider-submit {
  background: #02d2ff;
  background: -o-linear-gradient(315deg, #14e8ce 1%, #55e4ae 100%);
  background: linear-gradient(135deg, #14e8ce 1%, #55e4ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#14E8CE", endColorstr="#55E4AE",GradientType=1 );
  -webkit-box-shadow: 0 2px 0 0 #45ada1, 0 2px 5px 0 #45ada1;
  box-shadow: 0 2px 0 0 #45ada1, 0 2px 5px 0 #45ada1;
  border: none;
  width: 95%;
  padding: 0.8rem 0;
  border-radius: 0.3rem;
  color: #094e3b;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin: 3rem auto 0;
}

.help .paging-dot circle {
  fill: white !important;
}

/********

Choose game

********/
.choose {
  background-color: rgba(255, 255, 255, 0.69);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 21px 15px;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.choose button {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: transparent;
  border-radius: 6px;
  border: none;
  margin: 0 5px;
  padding: 13px 4px 5px;
}

.choose button:hover,
.choose button:active,
.choose button:focus {
  background-color: #ebf3ef;
}

.choose button img {
  max-width: 65%;
  margin: 0.7rem 0;
}

.choose button h2 {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: #2a9e71;
  line-height: 1.2;
}

.choose p {
  color: #11675c;
  font-weight: 700;
  letter-spacing: 0.3px;
  margin-top: 10px;

  line-height: 1.2;
}

.choose small {
  display: block;
  font-size: 75%;
  color: #628a85;
  margin: 5px auto 10px;
  height: 40px;
  width: 95%;
  letter-spacing: 0.2px;
  line-height: 1.2;
}

.choose__loader {
  mix-blend-mode: darken;
  width: 2rem !important;
  margin: 1.9rem 0 !important;
}

.game__loader {
  width: 2rem !important;
  margin: 10rem 0 !important;
}

/********

Toggle switch

********/
.switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  margin: 50px 0 60px;
}

.switch > span {
  font-size: 40px;
  font-weight: 600;
}

.switch-toggle {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 22px;
  border-radius: 100px;
  background-color: #f4f7f6;
  -webkit-box-shadow: 0 0 17px 0 #b1b1b1 inset;
  box-shadow: 0 0 17px 0 #b1b1b1 inset;
}

.switch-toggle input {
  display: none;
}

.switch-toggle div {
  position: absolute;
  border-radius: 50%;
  background-color: #abd4c4;
  -webkit-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  transition: 0.1s ease;
}

.switch-toggle input:checked + div {
  left: 50%;
  background-color: #08cc7f;
}

.inner div {
  width: 65px;
  height: 65px;
  top: -9px;
  left: 1px;
}

.outer div {
  width: 27px;
  height: 27px;
  top: -4px;
  left: -3px;
  -webkit-box-shadow: 0 1px 0 0 #06a768;
  box-shadow: 0 1px 0 0 #06a768;
}

.rect {
  border-radius: 0;
}

.rect div {
  border-radius: 0;
}

/********

How to play

********/
.how {
  /*background-color: #262b3a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 10px;*/
  margin-top: 10px;
}

.how img {
  /*margin-left: 10px;
  margin-bottom: -16px;*/
  width: 100%;
  position: relative;
  z-index: 2;
}

.how p {
  color: #b5b9c5;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.6px;
  font-weight: 500;
}

.how p a {
  display: block;
  color: #4fcba0;
  font-weight: 600;
  margin-top: 7px;
  font-size: 13px;
  letter-spacing: 1px;
}

.language {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.language > *:first-of-type {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
  margin-right: 0.3rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0 20px;
}

.language-inner:first-of-type > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
}

.language > *:first-of-type img {
  width: 35px;
}

.language > *:last-of-type {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 0.3rem;
}

.language-inner {
  background-color: #0c1523;
  border-radius: 11px;
  padding: 10px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.language button {
  padding: 0;
}

.language img {
  max-width: 65%;
}

.language h6 {
  text-align: center;
  font-weight: 700;
  color: #50c5c7;
  margin: 0 0 0 8px;
  line-height: 1.2;
  letter-spacing: 0.5px;
  font-size: 14px;
}

.language p {
  text-align: center;
  font-size: 0.8rem;
  display: block;
  margin-bottom: 0.5rem;
}

.language-top {
  margin: 8px 0 !important;
}

.language-top4 {
  margin-top: 4px !important;
}

.language-sched {
  padding: 15px 5px;
  position: relative;
  z-index: 2;
  margin-top: 20px;
}

.language-sched h6 {
  font-size: 15px;
}

.language-sched h6 img {
  width: 18px !important;
  margin-top: -2px;
  margin-right: 10px;
}

.language-nogame {
  padding: 9px 5px;
}

.language-nogame h6 {
  font-size: 13px;
  display: flex;
  align-items: center;
  line-height: 1;
  color: #f9ad22;
}

.language-nogame h6 img {
  width: 13px !important;
}

.countries {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.countries button {
  -webkit-filter: opacity(0.5);
  filter: opacity(0.5);
}

.countries .selected {
  -webkit-filter: opacity(1);
  filter: opacity(1);
}

/********

ADs

********/
.ads__text {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 10;
  width: 95%;
  background-color: rgba(21, 24, 33, 0.9);
  padding: 10px 25px 10px 66px;
  border-radius: 50px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-weight: 500;
  font-size: 15px;
  line-height: 1.3;
  letter-spacing: 0.3px;
  min-height: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.ads__text a {
  color: #5ae5a6;
}

.ads__text img {
  position: absolute;
  left: 25px;
  width: 35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ads__text small {
  display: block;
  font-size: 10px;
  width: 90%;
  margin-top: 5px;
  font-weight: 300;
  color: rgb(252, 198, 161);
}

.ads__announcement {
  bottom: 1rem !important;
}

.ads__cont {
  position: relative;
}

.ads__cont-bot {
  margin-top: 3rem;
}

.ads__guide {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/********

Placeholder animation

********/
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.placeholder-anim {
  background-color: #f3f3f3;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(5%, #f3f3f3),
    color-stop(20%, #eee),
    color-stop(35%, #f3f3f3)
  );
  background: -o-linear-gradient(left, #f3f3f3 5%, #eee 20%, #f3f3f3 35%);
  background: linear-gradient(to right, #f3f3f3 5%, #eee 20%, #f3f3f3 35%);
  -webkit-animation: placeHolderShimmer 2s linear 0s infinite normal forwards;
  animation: placeHolderShimmer 2s linear 0s infinite normal forwards;
}

.placeholder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 14px 0;
}

.placeholder span {
  border-radius: 5px;
}

.placeholder span:first-of-type {
  height: 70px;
  display: block;
  width: 200px;
  margin-left: 15px;
  margin-right: 10px;
}

.placeholder span:last-of-type span:first-of-type {
  height: 42px;
  display: block;
  width: 110px;
  margin-left: 0;
}

.placeholder span:last-of-type span:last-of-type {
  height: 20px;
  display: block;
  width: 110px;
  margin-left: 0;
  margin-top: 10px;
}

/********

Gameboard

********/
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-content-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.invisible {
  visibility: hidden;
}
.disable {
  pointer-events: none;
}
.game-notstarted h1 {
  font-size: 1.3rem;
  max-width: 80%;
  margin: 3rem auto;
  color: white;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.02rem;
}
.game-notstarted h2 {
  position: absolute;
  bottom: 15%;
  left: 50%;
  z-index: 5;
  max-width: 76%;
  font-size: 1.2em;
  width: 100%;
  padding: 0.5rem;
  border-radius: 4rem;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  line-height: 1.2;
  margin: 2rem auto 0;
  color: #ffffff;
  font-weight: 600;
  text-shadow: 0 2px 11px #000;
}
.game-nogame {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: rgb(199, 3, 3);
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 1.2rem;
  min-width: 73%;
  letter-spacing: 0.01rem;
  box-shadow: 0 2px 10px 0 rgba(25, 3, 3, 0.55);
}
.game-tiles {
  position: relative;
  top: 14px;
}
.game-tiles h1 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 15px;
  color: white;
  position: relative;
  margin-top: 0;
}
.game-tiles h1 span {
  position: relative;
  width: 2.8rem;
  height: 3rem;
  display: inline-block;
  position: relative;
  top: 1rem;
}
.game-tiles h1 span strong {
  font-weight: 700;
  height: 80%;
  font-size: 95%;
}
.game-tiles .modal-submit {
  margin-top: 1.5rem;
}
.game-tiles .modal-submit.disabled {
  pointer-events: none;
  background: #b9cac4;
  -webkit-box-shadow: 0 2px 0 0 #7f8c8a;
  box-shadow: 0 2px 0 0 #7f8c8a;
}
.game-tiles > div > div {
  color: #fff;
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 700;
  position: relative;
  text-shadow: 0 2px 7px #757575;
}
.game-pattern {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 20px;
  max-height: 235px;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.69);
}
.game-pattern > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.game-pattern > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.game-pattern > *:nth-child(3) {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}
.game-pattern > *:nth-child(4) {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
}
.game-pattern > *:nth-child(5) {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
}
.game-pattern > *:nth-child(6) {
  -ms-grid-row: 5;
  -ms-grid-column: 3;
}
.game-pattern > *:nth-child(7) {
  -ms-grid-row: 7;
  -ms-grid-column: 1;
}
.game-pattern > *:nth-child(8) {
  -ms-grid-row: 7;
  -ms-grid-column: 3;
}
.game-pattern img {
  width: 75px;
}
.game-pattern p {
  text-align: center;
  padding: 7px 1px;
}
.game-pattern p:hover,
.game-pattern p:active,
.game-pattern p:focus {
  background-color: #ebf3ef;
  border-radius: 5px;
}
.game-pattern p span {
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  display: block;
  letter-spacing: 0.5px;
  line-height: 1.1;
  margin-top: 3px;
}

@media screen and (max-height: 600px) {
  .game-tiles > div > div {
    width: 40px;
    height: 38px;
  }
}

.text-center {
  text-align: center;
}
.text-white {
  color: #fff;
}
/* .red {
  /*border-radius: 5px;
} */
.red:before {
  content: "";
  width: 101%;
  height: 88%;
  position: absolute;
  top: -3px;
  left: 0;
  z-index: 3;
  border: 2.5px solid #d64c63;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 1px 0 #b04160 inset;
  box-shadow: 0 5px 1px 0 #b04160 inset;
}
.red:after {
  content: "";
  background-color: #a01f47;
  position: absolute;
  top: -3px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 5px;
}
.red span {
  background: #dd8592;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f5667b),
    to(#a91127)
  );
  background: -o-linear-gradient(top, #f5667b 0%, #a91127 100%);
  background: linear-gradient(to bottom, #f5667b 0%, #a91127 100%);
  position: absolute;
  top: 5px;
  left: 2px;
  z-index: 2;
  width: 93%;
  height: 68%;
  overflow: hidden;
  border-radius: 6px;
}
.red span:before {
  content: "";
  background-color: #da344d;
  top: 0.5rem;
  left: 0;
  width: 117%;
  height: 100%;
  position: absolute;
  border-radius: 100%;
}
.red span:after {
  content: "";
  background-color: #d02b44;
  top: 1.5rem;
  left: 0;
  width: 117%;
  height: 100%;
  position: absolute;
  border-radius: 100%;
}

.red strong {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 19px;
  font-weight: 800;
}

@media screen and (max-height: 600px) {
  .red span {
    top: 2px;
  }
  .red strong {
    font-size: 16px;
  }
}

.gray:before {
  border-color: #8f9a9e;
  -webkit-box-shadow: 0 5px 1px 0 #708288 inset;
  box-shadow: 0 5px 1px 0 #708288 inset;
}

.gray:after {
  background-color: #495355;
}

.gray span {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#aab3b7),
    to(#8f9a9e)
  );
  background: -o-linear-gradient(top, #aab3b7 0%, #8f9a9e 100%);
  background: linear-gradient(to bottom, #aab3b7 0%, #8f9a9e 100%);
}

.gray span:before {
  background-color: #8a9ba0;
}

.gray span:after {
  background-color: #829398;
}

/*.yellow .gray:before {
  border-color: #1295ab;
  box-shadow: 0 5px 1px 0 #118194 inset;
}

.yellow .gray:after {
  background-color: #086575;
}

.yellow .gray span {
  background: linear-gradient(#25b9d2 0%, #106877 100%);
}

.yellow .gray span:before {
  background-color: #26a5b9;
}

.yellow .gray span:after {
  background-color: #0999a5;
}*/

.yellow .gameboard {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(22, 92, 144, 0.02)),
    to(rgb(13, 17, 21))
  );
  background: -o-linear-gradient(
    rgba(22, 92, 144, 0.02) 0%,
    rgb(13, 17, 21) 100%
  );
  background: linear-gradient(rgba(22, 92, 144, 0.02) 0%, rgb(13, 17, 21) 100%);
}

.green .gray:before {
  border-color: #19c18f;
  -webkit-box-shadow: 0 5px 1px 0 #19b385 inset;
  box-shadow: 0 5px 1px 0 #19b385 inset;
}

.green .gray:after {
  background-color: #16a579;
}

.green .gray span {
  background: -o-linear-gradient(#39d4a5 0%, #1abd8d 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#39d4a5),
    to(#1abd8d)
  );
  background: linear-gradient(#39d4a5 0%, #1abd8d 100%);
}

.green .gray span:before {
  background-color: #22d6a0;
}

.green .gray span:after {
  background-color: #21cc99;
}

.hotviolet .gray:before {
  border-color: #d597ce;
  box-shadow: 0 5px 1px 0 #ca79c0 inset;
}

.hotviolet .gray:after {
  background-color: #b772af;
}

.hotviolet .gray span {
  background: linear-gradient(#e0a9da 0%, #d597ce 100%);
}

.hotviolet .gray span:before {
  background-color: #d68dce;
}

.hotviolet .gray span:after {
  background-color: #cc7fc3;
}

.hotviolet .gameboard {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(32, 115, 108, 0)),
    to(#1d2120)
  );
  background: -o-linear-gradient(rgba(32, 115, 108, 0) 0%, #1d2120);
  background: linear-gradient(rgba(32, 115, 108, 0) 0%, #1d2120);
}

.blue:before {
  border-color: #57ad8b;
  box-shadow: 0 5px 1px 0 #53967c inset;
}

.blue:after {
  background-color: #348062;
}

.blue span {
  background: linear-gradient(#6fd8af 0%, #74b49b 100%);
}

.blue span:before {
  background-color: #58bd95;
}

.blue span:after {
  background-color: #4dab85;
}

.blue .gameboard {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 19, 13, 0)),
    to(rgb(0, 37, 32))
  );
  background: -o-linear-gradient(rgba(0, 19, 13, 0) 0%, rgb(0, 37, 32) 100%);
  background: linear-gradient(rgba(0, 19, 13, 0) 0%, rgb(0, 37, 32) 100%);
}

.pattern:before {
  border-color: #2eb9a7;
  box-shadow: 0 5px 1px 0 #1a9a89 inset;
  -webkit-box-shadow: 0 5px 1px 0 #1a9a89 inset;
}

.pattern:after {
  background-color: #0c7b6c;
}

.pattern span {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#2dc1af),
    to(#95fff3)
  );
  background: -o-linear-gradient(top, #2dc1af 0%, #95fff3 100%);
  background: linear-gradient(to bottom, #2dc1af 0%, #95fff3 100%);
}

.pattern span:before {
  background-color: #43c8b7;
}

.pattern span:after {
  background-color: #3aceb8;
}


.nba-1 .gameboard {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(21, 24, 33, 0)),
    to(rgb(37, 7, 49))
  );
  background: -o-linear-gradient(
    top,
    rgba(21, 24, 33, 0) 0%,
    rgb(37, 7, 49) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(21, 24, 33, 0) 0%,
    rgb(37, 7, 49) 100%
  );
}

.violet:before {
  border-color: #7f5c8c;
  -webkit-box-shadow: 0 5px 1px 0 #775386 inset;
  box-shadow: 0 5px 1px 0 #775386 inset;
}

.violet:after {
  background-color: #5e2470;
}

.violet span {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#a783b5),
    to(#a783b5)
  );
  background: -o-linear-gradient(top, #a783b5 0%, #a783b5 100%);
  background: linear-gradient(to bottom, #a783b5 0%, #a783b5 100%);
}

.violet span:before {
  background-color: #9976a7;
}

.violet span:after {
  background-color: #8e6a9c;
}

.match:before {
  border-color: #f7cb11;
  -webkit-box-shadow: 0 5px 1px 0 #dd9d11 inset;
  box-shadow: 0 5px 1px 0 #dd9d11 inset;
}

.match:after {
  background-color: #b78e34;
}

.match span {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fddd62),
    to(#fddd62)
  );
  background: -o-linear-gradient(top, #fddd62 0%, #fddd62 100%);
  background: linear-gradient(to bottom, #fddd62 0%, #fddd62 100%);
}

.match span:before {
  background-color: #f1d051;
}

.match span:after {
  background-color: #e0bd34;
}

.match strong:before {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTc4MzEgMS42ODMyOEMxMC40NTczIDAuMjA5MTgzIDEyLjU0MjcgMC4yMDkxODQgMTMuMDIxNyAxLjY4MzI4TDE0LjYyMDcgNi42MDQ2NkMxNC44MzQ5IDcuMjYzOSAxNS40NDkzIDcuNzEwMjQgMTYuMTQyNCA3LjcxMDI0SDIxLjMxNzFDMjIuODY3IDcuNzEwMjQgMjMuNTExNSA5LjY5MzYyIDIyLjI1NzUgMTAuNjA0N0wxOC4wNzEyIDEzLjY0NjJDMTcuNTEwNCAxNC4wNTM3IDE3LjI3NTcgMTQuNzc1OSAxNy40ODk5IDE1LjQzNTFMMTkuMDg5IDIwLjM1NjVDMTkuNTY3OSAyMS44MzA2IDE3Ljg4MDggMjMuMDU2NCAxNi42MjY4IDIyLjE0NTNMMTIuNDQwNSAxOS4xMDM4QzExLjg3OTcgMTguNjk2MyAxMS4xMjAzIDE4LjY5NjMgMTAuNTU5NSAxOS4xMDM4TDYuMzczMTYgMjIuMTQ1M0M1LjExOTIyIDIzLjA1NjQgMy40MzIwNiAyMS44MzA2IDMuOTExMDIgMjAuMzU2NUw1LjUxMDA3IDE1LjQzNTFDNS43MjQyNyAxNC43NzU5IDUuNDg5NjIgMTQuMDUzNyA0LjkyODg0IDEzLjY0NjJMMC43NDI0NiAxMC42MDQ3Qy0wLjUxMTQ4MiA5LjY5MzYyIDAuMTMyOTYxIDcuNzEwMjQgMS42ODI5MiA3LjcxMDI0SDYuODU3NTdDNy41NTA3MyA3LjcxMDI0IDguMTY1MDYgNy4yNjM5IDguMzc5MjYgNi42MDQ2Nkw5Ljk3ODMxIDEuNjgzMjhaIiBmaWxsPSIjRUY2NTNBIi8+Cjwvc3ZnPgo=);
  position: absolute;
  z-index: -1;
  opacity: 0.4;
}

.match2:before {
  border-color: #18d819;
  box-shadow: 0 5px 1px 0 #0acc10 inset;
}
.match2:after {
  background-color: #269c11;
}
.match2 span {
  background: linear-gradient(to bottom, #37ef20 0%, #40dc0c 100%);
}
.match2 span:before {
  background-color: #2ee00d;
}
.match2 span:after {
  background-color: #30ea20;
}

.match2 strong:before {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTc4NTQgMS42ODM3QzEwLjQ1NzUgMC4yMDk2IDEyLjU0MjkgMC4yMDk2MDEgMTMuMDIxOSAxLjY4MzdMMTQuNjIwOSA2LjYwNTA4QzE0LjgzNTEgNy4yNjQzMiAxNS40NDk1IDcuNzEwNjYgMTYuMTQyNiA3LjcxMDY2SDIxLjMxNzNDMjIuODY3MiA3LjcxMDY2IDIzLjUxMTcgOS42OTQwNCAyMi4yNTc3IDEwLjYwNTFMMTguMDcxNCAxMy42NDY2QzE3LjUxMDYgMTQuMDU0MSAxNy4yNzU5IDE0Ljc3NjMgMTcuNDkwMSAxNS40MzU1TDE5LjA4OTIgMjAuMzU2OUMxOS41NjgxIDIxLjgzMSAxNy44ODEgMjMuMDU2OCAxNi42MjcgMjIuMTQ1N0wxMi40NDA3IDE5LjEwNDJDMTEuODc5OSAxOC42OTY3IDExLjEyMDUgMTguNjk2NyAxMC41NTk3IDE5LjEwNDJMNi4zNzMzOSAyMi4xNDU3QzUuMTE5NDUgMjMuMDU2OCAzLjQzMjI5IDIxLjgzMSAzLjkxMTI1IDIwLjM1NjlMNS41MTAzIDE1LjQzNTVDNS43MjQ1IDE0Ljc3NjMgNS40ODk4NSAxNC4wNTQxIDQuOTI5MDcgMTMuNjQ2NkwwLjc0MjY4OCAxMC42MDUxQy0wLjUxMTI1NCA5LjY5NDA0IDAuMTMzMTg5IDcuNzEwNjYgMS42ODMxNSA3LjcxMDY2SDYuODU3OEM3LjU1MDk2IDcuNzEwNjYgOC4xNjUyOSA3LjI2NDMyIDguMzc5NDkgNi42MDUwOEw5Ljk3ODU0IDEuNjgzN1oiIGZpbGw9IiMyM0FDMEEiLz4KPC9zdmc+Cg==);
  position: absolute;
  z-index: -1;
  opacity: 0.8;
}

.border-game-tile-top {
  -webkit-box-shadow: -4px -5px 0 3px #02d2ff, -5px -5px 0 0 #02d2ff,
    1px -3px 0 5px #02d2ff;
  box-shadow: -4px -5px 0 3px #02d2ff, -5px -5px 0 0 #02d2ff,
    1px -3px 0 5px #02d2ff;
  border-radius: 3px;
}
.border-game-tile-tl {
  -webkit-box-shadow: -1px -4px 0 5px #02d2ff, -5px -4px 0 0 #02d2ff;
  box-shadow: -1px -4px 0 5px #02d2ff, -5px -4px 0 0 #02d2ff;
  border-radius: 3px;
}
.border-game-tile-lb {
  /*box-shadow: -3px -2px 0 3px #02d2ff, -3px 2px 0 3px #02d2ff;*/
  border-radius: 3px;
  -webkit-box-shadow: 1px -4px 0 7px #5ae5a6, -5px -1px 0 0px #5ae5a6;
  box-shadow: 1px -4px 0 7px #5ae5a6, -5px -1px 0 0px #5ae5a6;
  background-color: #5ae5a6;
}
.border-game-tile-lb > div {
  -webkit-box-shadow: -2px 5px 0 4px rgba(1, 210, 255, 0.31);
  box-shadow: -2px 5px 0 4px rgba(1, 210, 255, 0.31);
  width: 100%;
  height: 100%;
  border-radius: 2px;
}
.border-game-tile-bottom {
  /* box-shadow: -3px 6px 0 0 #02d2ff, -3px 3px 0 3px #02d2ff, 4px 4px 0 2px #02d2ff;*/
  border-radius: 3px;
  -webkit-box-shadow: -4px 2px 0 0 #3edfc2, -5px 2px 0 1px #3edfc2,
    5px 2px 0 1px #3edfc2;
  box-shadow: -4px 2px 0 0 #3edfc2, -5px 2px 0 1px #3edfc2,
    5px 2px 0 1px #3edfc2;
  background-color: #3edfc2;
}
.border-game-tile-tr {
  -webkit-box-shadow: 1px -5px 0 3px #02d2ff, 5px -7px 0 1px #02d2ff;
  box-shadow: 1px -5px 0 3px #02d2ff, 5px -7px 0 1px #02d2ff;
  border-radius: 3px;
}
.border-game-tile-rb {
  /*box-shadow: -1px -2px 0 7px #01d2ff, 3px 3px 0 3px #01d2ff;*/
  border-radius: 3px;
  -webkit-box-shadow: 1px -3px 0 5px #5ae5a6, 4px -1px 0 2px #5ae5a6;
  box-shadow: 1px -3px 0 5px #5ae5a6, 4px -1px 0 2px #5ae5a6;
  background-color: #5ae5a6;
}

.border-game-tile-rb > div {
  -webkit-box-shadow: 1px 6px 0 4px rgba(1, 210, 255, 0.31);
  box-shadow: 1px 6px 0 4px rgba(1, 210, 255, 0.31);
  width: 100%;
  height: 100%;
  border-radius: 2px;
}
.border-game-tile-rb:after {
  content: "";
  background-color: #5ae5a6;
  position: absolute;
  bottom: -6px;
  right: -6px;
  width: 113%;
  height: 100%;
  border-radius: 9px;
  z-index: -1;
}
.border-game-tile-rb:before {
  content: "";
  background-color: #5ae5a6;
  position: absolute;
  bottom: 0;
  right: -6px;
  width: 113%;
  height: 100%;
  border-radius: 5px;
  z-index: -1;
}
.border-game-tile-bottom:after {
  content: "";
  /*background-color: rgba(1, 210, 255, 0.21);*/
  background-color: #5ae5a6;
  position: absolute;
  bottom: -10px;
  right: -6px;
  width: 128%;
  height: 100%;
  border-radius: 7px;
  z-index: -1;
}
.border-game-tile-bottom:before {
  content: "";
  /*background-color: rgba(1, 210, 255, 0.21);*/
  background: #41bd85;
  position: absolute;
  bottom: -14px;
  right: -6px;
  width: 128%;
  height: 100%;
  border-radius: 7px;
  z-index: -1;
}
.border-game-tile-lb:after {
  content: "";
  background-color: #235f44;
  position: absolute;
  bottom: -13px;
  right: 0px;
  width: 113%;
  height: 100%;
  border-radius: 5px;
  z-index: -1;
}
.border-game-tile-lb:before {
  content: "";
  background-color: #5ae5a6;
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 113%;
  height: 100%;
  border-radius: 5px;
  z-index: -1;
}

.border-game-tile-top-center {
  -webkit-box-shadow: 0px -9px 0 0 #02d2ff;
  box-shadow: 0px -9px 0 0 #02d2ff;
}

.border-gradient {
  background: rgb(2, 210, 255);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgb(90, 229, 166)),
    to(rgba(90, 229, 166, 1))
  );
  background: -o-linear-gradient(
    top,
    rgb(90, 229, 166) 0%,
    rgba(90, 229, 166, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgb(90, 229, 166) 0%,
    rgba(90, 229, 166, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02d2ff', endColorstr='#5ae5a6',GradientType=0 );
  -webkit-box-shadow: 0px -2px 0 6px #3edfc2, 3px 3px 0 1px #02d2ff;
  box-shadow: 0px -2px 0 6px #3edfc2, 3px 3px 0 1px #02d2ff;
}

.border-gradient-top {
  -webkit-box-shadow: 0px -2px 0 6px #3edfc2, 3px 3px 0 1px #02d2ff;
  box-shadow: 0px -2px 0 6px #3edfc2, 3px 3px 0 1px #02d2ff;
}

.border-gradient-inner {
  -webkit-box-shadow: 1px 2px 0 7px #02d2ff;
  box-shadow: 1px 2px 0 7px #02d2ff;
}

.gameboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 12vh;
  height: 103vh;
  background-size: contain;
  /* background: -moz-linear-gradient(
    top,
    rgba(21, 24, 33, 0) 0%,
    rgb(4, 56, 62) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(21, 24, 33, 0) 0%,
    rgb(4, 56, 62) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(21, 24, 33, 0) 0%,
    rgb(4, 56, 62) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00151821', endColorstr='#151821',GradientType=0 ); */
}

.gameboard > div {
  width: 100%;
}

.gameboard h3,
.gameboard h4,
.gameboard p {
  color: white;
}
.gameboard:after {
  content: "";
  background-image: url(/images/gamebg.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.gameboard h3 {
  font-size: 25px;
  font-weight: 500;
  margin: 14px auto 80px;
  background-color: #29a199;
  padding: 4px 0 7px;
  width: 50%;
  line-height: 1;
  letter-spacing: -0.5px;
  border-radius: 50px;
  color: #353c58;
  background: rgb(2, 210, 255);
  background: -o-linear-gradient(
    315deg,
    rgba(2, 210, 255, 1) 0%,
    rgba(91, 229, 167, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(2, 210, 255, 1) 0%,
    rgba(91, 229, 167, 1) 100%
  );
}

.gameboard h4 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 3px;
  margin-top: 1px;
  color: white;
  letter-spacing: -0.1px;
}

.game-time {
  width: 100%;
  height: 70px;
  position: relative;
  top: 45px;
  margin-top: 30px;
}

.game-time .panel__main {
  background-image: url("/images/panel.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.game-time .panel {
  width: 100%;
  height: 100%;
  background: red;
  -webkit-transform: perspective(600px) rotateX(45deg);
  transform: perspective(600px) rotateX(45deg);
  border-radius: 10px;
  background: #5ae5a6;
  background: -o-linear-gradient(top, #5ae5a6 0%, #02d2ff 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#5ae5a6),
    to(#02d2ff)
  );
  background: linear-gradient(to bottom, #5ae5a6 0%, #02d2ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5ae5a6', endColorstr='#02d2ff',GradientType=0 );
  -webkit-box-shadow: 0 0 30px 0 #047f8c;
  box-shadow: 0 0 30px 0 #047f8c;
}
.game-time .panel:nth-of-type(1) {
  position: relative;
  z-index: 3;
  top: 32px;
}
.game-time .panel:nth-of-type(2) {
  top: -32px;
  position: relative;
  z-index: 2;
  opacity: 0.5;
}

.game-time .panel:nth-of-type(3) {
  top: -96px;
  position: relative;
  opacity: 0.1;
}
.game-start-time {
  position: relative;
}
.game-start-time.not {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.1;
}
.game-notstarted {
  position: relative;
  top: 5rem;
}
.game-time-detail {
  /*  filter: grayscale(1);
  -webkit-filter: grayscale(1);*/
  /*filter: brightness(70%);
  -webkit-filter: brightness(70%);*/
  position: absolute;
  width: 91%;
  top: 7px;
  z-index: 4;
}
.game-time-detail-container {
  position: relative;
  z-index: 4;
  width: 78%;
  margin: 0 auto 50px;
}
.game-time-detail-container .number-of-hour {
  max-width: 23%;
  margin: 0 2px;
  width: 100%;
}
.game-time-detail-container .number-of-hour .number-container {
  border: 4px solid #f7cb11;
  border-radius: 9px;
  cursor: pointer;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;
  height: 55px;
  width: 57px;
  -webkit-box-reflect: below 5px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(67%, transparent), to(rgba(250, 250, 250, 0.5)));
  -webkit-box-shadow: 0 5px 0 0 #b78e34;
  box-shadow: 0 5px 0 0 #b78e34;
}
.game-time-detail-container .number-of-hour h2 {
  /* margin-top: 6px;
  margin-bottom: 25px; */
  font-weight: bolder;
  font-size: 32px;
  color: white;
  text-shadow: 3px 5px 10px #4f715b, 1px 0 10px #57e4a9, 1px 2px 0px #065836;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #e5fff6;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 47px;
}
.game-time-detail-container .number-of-hour p {
  font-size: 72%;
  font-weight: 600;
  position: relative;
  top: -4px;
  color: white;
}
.game-time-detail-container .number-of-hour .midnight {
  background-image: url("/images/midnight.jpg");
}
.game-time-detail-container .number-of-hour .morning {
  background-image: url("/images/morning.jpg");
}
.game-time-detail-container .number-of-hour .afternoon {
  background-image: url("/images/afternoon.jpg");
}
.game-time-detail-container .number-of-hour .night {
  background-image: url("/images/night.jpg");
}
.game-time-detail-container .number-of-hour .bg-style-time {
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  border-radius: 5px;
}
.game-time-detail-container .number-of-hour .number-container:after {
  content: "";
  -webkit-box-shadow: inset 0px 4px 1px #dd9d11, 0px 0px 1px #ba8824;
  box-shadow: inset 0px 4px 1px #dd9d11, 0px 0px 1px #ba8824;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  /* height: 87%; */
  height: 100%;
  border-radius: 4px;
}

.taken h2:before {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNDQiIHZpZXdCb3g9IjAgMCA0NCA0NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM3LjU1NjcgNi40NDMyNUMzMy40MDE1IDIuMjg4IDI3Ljg3NjggMCAyMiAwQzE2LjEyMzIgMCAxMC41OTg1IDIuMjg4IDYuNDQzMjUgNi40NDMyNUMyLjI4OCAxMC41OTg1IDAgMTYuMTIzMiAwIDIyQzAgMjcuODc2OCAyLjI4OCAzMy40MDE1IDYuNDQzMjUgMzcuNTU2N0MxMC41OTg1IDQxLjcxMiAxNi4xMjMyIDQ0IDIyIDQ0QzI3Ljg3NjggNDQgMzMuNDAxNSA0MS43MTIgMzcuNTU2NyAzNy41NTY3QzQxLjcxMiAzMy40MDE1IDQ0IDI3Ljg3NjggNDQgMjJDNDQgMTYuMTIzMiA0MS43MTIgMTAuNTk4NSAzNy41NTY3IDYuNDQzMjVaTTM4LjUgMjJDMzguNTAzNyAyNS40MjYyIDM3LjQzNTQgMjguNzY3NyAzNS40NDQ3IDMxLjU1NjNMMTIuNDQzOCA4LjU1NTI1QzE1LjIzMjMgNi41NjQ2MyAxOC41NzM4IDUuNDk2MzIgMjIgNS41QzMxLjA5NyA1LjUgMzguNSAxMi45MDMgMzguNSAyMlpNNS41IDIyQzUuNDk2MzIgMTguNTczOCA2LjU2NDYzIDE1LjIzMjMgOC41NTUyNSAxMi40NDM4TDMxLjU1NjMgMzUuNDQ0N0MyOC43Njc3IDM3LjQzNTQgMjUuNDI2MiAzOC41MDM3IDIyIDM4LjVDMTIuOTAzIDM4LjUgNS41IDMxLjA5NyA1LjUgMjJaIiBmaWxsPSIjQzIyMDIwIi8+Cjwvc3ZnPgo=);
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -40%);
  -ms-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);
  opacity: 0.7;
  width: 100%;
}

@media screen and (max-height: 650px) {
  .ads__cont-bot {
    margin-top: 2rem;
  }
  .gameboard {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-bottom: 0;
    height: 103vh;
  }
  .gameboard h3 {
    margin: 18px auto 80px;
  }
  .gameboard h4 {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
  }
  .game-time-detail {
    top: -3px;
  }
  .game-time-detail-container .number-of-hour {
    max-width: 21%;
  }
  .game-time-detail-container .number-of-hour .number-container {
    height: 51px;
  }
  .game-time-detail-container .number-of-hour h2 {
    margin-top: -1px;
    font-size: 26px;
  }
  .game-time {
    top: 31px;
    width: 91%;
    height: 60px;
    margin: 15px auto 0;
  }
  .game-tiles {
    position: initial;
  }
  .chooseNum {
    margin: 0.9rem auto;
  }
}

/********

Loading Screen

********/
.loadingScreen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
}

.loadingScreen img {
  width: 8rem;
}

.loadingScreen p {
  color: white;
  font-size: 1.3rem;
  margin-top: 1rem;
}

.statLoader {
  width: 1.7rem;
}

/* mathitads */

.player-wrapper {
  /* position: relative;
  padding-top: 56.25%; Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#react-player {
  position: fixed;
  left: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
}

.player-wrapper button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  padding: 0;
  background-color: #08cc7f;
  border: none;
}

.player-wrapper span {
  z-index: -1;
}
.adsBanner {
  left: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: auto;
  z-index: -1;
  position: fixed;
}

.howtoplaybtn {
  position: fixed;
  z-index: 1;
  border-radius: 0;
  color: #fff;
}
.chooseNum {
  font-size: 12px !important;
  display: block;
  position: initial !important;
  /* margin: 1.3rem auto;
  top: -9px !important; */
}
.chooseNum span {
  display: block;
}
/* Loading GIF */
.lds-spinner {
  /* display: inline-block; */
  position: relative;
  /* top: -3.2rem; */
  /* left: -2.4rem; */
}
.lds-spinner div {
  -webkit-transform-origin: 40px 40px;
  -ms-transform-origin: 40px 40px;
  transform-origin: 40px 40px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 18px;
  left: 37px;
  width: 3px;
  height: 11px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Media Queries */
@media screen and (max-height: 600px) {
  .chooseNum {
    top: -15px !important;
  }
  /* .ads__text {
    bottom: -75px;
  } */
}
@media screen and (min-width: 900px) {
  .adsBanner {
    top: -110%;
  }
}
@media screen and (max-width: 991px) {
  .form-inline input,
  .form-inline button {
    width: 90%;
    margin: 0.5rem auto;
  }
  .form-inline button {
    padding: 0.9rem 0;
  }
}
@media screen and (min-width: 700px) {
  .chooseNum {
    font-size: 1rem;
    margin: 1rem auto;
  }
}
@media screen and (max-width: 380px) {
  .prize,
  .winners .slider-frame {
    height: 330px;
  }

  .gamelist__info > ion-icon {
    bottom: auto;
    top: 0;
    right: 0;
    font-size: 20px;
    color: #42bcb2;
    position: absolute;
  }
}

.vol__rocker {
  -webkit-appearance: none;
  background-color: rgba(9, 11, 15, 0.76) !important;
  left: 50% !important;
  bottom: 40%;
  top: auto !important;
  width: unset !important;
  height: unset !important;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem !important;
}

.vol__rocker ion-icon {
  font-size: 2.5rem;
}

/* .vol__rocker:active {
  transform: scale(1.2);
} */


.newgame__gameboard {
  overflow: hidden;
  height: 100vh;
}
.newgame__gameboard:after {
  background-image: url(/images/helping.jpg);
}
.newgame__gameboard h3 {
  margin: 50px auto 10px;
  text-align: center;
}
.newgame__gameboard .bottom-text {
  margin-top: 40px;
}
.newgame__gameboard .bottom-text h4 {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin-bottom: 3px;
  margin-top: 1px;
  color: white;
  letter-spacing: -0.1px;
}
.newgame__wrapper {
  overflow: visible;
  position: relative;
  min-height: 410px;
}
.newgame__wrapper > *:after {
  content: attr(data-num);
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 40px;
  font-weight: 800;
  font-size: 35px;
  letter-spacing: -2px;
  text-shadow: -3px 3px 2px #4c555ab0;
}
.newgame__house {
  position: relative;
  max-width: 95%;
}
.newgame__house img {
  max-width: 380px;
}
.newgame__wrapper svg path {
  fill: #525e7b;
}
.newgame__house:before {
  content: '';
  /* background-color: rgb(168 177 182 / .5); */
  position: absolute;
  bottom: 2rem;
  left: 65px;
  width: 280px;
  height: 50%;
  transform: perspective(15rem) rotateY(13deg);
  border-radius: .2rem;
}
.newgame__house:after {
  top: 48px;
  left: 94px;
}
.newgame__pole {
  position: absolute;
  right: 10px;
  top: 80px;
}
.newgame__pole:after {
  top: 27px;
  right: 17px;
  font-size: 28px;
  text-shadow: -1px 3px 2px #4c555ab0;
}
.newgame__pole img {
  width: 55px;
}
.newgame__pole svg {
  position: absolute;
  top: -3px;
  left: -3px;
  width: 60px;
  height: 240px;
}
.newgame__pole svg path {
  stroke: #5f7f88;
  stroke-width: 2px;
}
.newgame__housechimney {
  position: absolute;
  top: 25px;
  left: 66px;
  width: 58px;
  height: 54px;
}
.newgame__housenumbers {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 4px;
  position: absolute;
  bottom: 36px;
  left: 65px;
  width: 285px;
  height: 155px;
  transform: perspective(14rem) rotateY(13deg);
  /* background-color: rgba(31, 39, 59, 0.7); */
  padding: 7px;
  border-radius: 5px;
}
.newgame__housenumbers button:nth-of-type(3) {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  align-self: stretch;
  justify-content: center;
}
.newgame__housenumbers button {
  background-color: rgba(32, 40, 59, 0.55);
  border-radius: 4px;
  color: #fff;
  font-weight: 800;
  font-size: 35px;
  letter-spacing: -2px;
  text-shadow: -3px 3px 2px #6b808cb0;
}
.newgame__housenumbers button.-selected {
  background-color: #f7cb50;
  text-shadow: -3px 3px 2px #cca849;
}
.newgame__person {
  position: absolute;
  top: 12rem;
  right: 3px;
}
.newgame__person:after {
  top: 5rem;
  right: 17px;
}
.newgame__person img {
  min-width: 5rem;
}
.newgame__person svg {
  position: absolute;
  right: -52px;
  bottom: 6px;
  width: 185px;
  height: 183px;
}
.newgame__person svg path {
  stroke: #5f7f88;
  stroke-width: 2px;
}
.newgame__car {
  position: absolute;
  bottom: -45px;
  left: 0.5rem;
}
.newgame__car:after {
  top: 45%;
  left: 60%;
}
.newgame__car img {
  min-width: 330px;
}
.newgame__car svg {
  position: absolute;
  right: 47px;
  bottom: 42px;
  width: 238px;
  height: 162px;
}
.newgame__car svg path {
  stroke: #5f7f88;
  stroke-width: 2px;
}
.newgame__motorcycle {
  position: absolute;
  bottom: -5px;
  left: -53px;
  z-index: 2;
}
.newgame__motorcycle:after {
  top: 75px;
  right: 51px;
}
.newgame__motorcycle img {
  min-width: 155px;
}
.newgame__motorcycle svg {
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 188px;
  height: 120px;
}
.newgame__motorcycle svg path {
  stroke: #5f7f88;
  stroke-width: 2px;
}

.newgame__gameboard .game-time .panel__main {
  width: 110%;
  margin-left: -6%;
}
.newgame__gameboard .game-time-detail-container {
  width: 95%;
}
.newgame__gameboard .game-time-detail-container .number-of-hour h2 {
  min-height: 13px;
}
.newgame__gameboard .game-time-detail {
  top: -13px;
}
.newgame__gameboard .game-time {
  top: 29px;
}
.newgame__gameboard .game-start-time {
  max-width: 371px;
  margin: 0 auto;
}
.newgame__game-tiles {
  margin-top: 80px;
  position: initial;
}
.newgame__game-tiles:after {
  content: '';
  background-image: url(/images/helping.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}
.newgame__game-tiles h1 {
  text-align: center;
  margin: 20px 0 30px;
}
.newgame__game-tiles > div > div {
  width: 34px;
  height: 50px;
  margin: 0 3px;
} 
.newgame__game-tiles .pattern:before {
  display: none;
}
.newgame__game-tiles .pattern:after {
  height: 90%;
  top: 4px;
}
.newgame__game-tiles .pattern span {
  border-radius: 4px;
  height: 85%;
}